$white: #fff;
$purple: #8924f4;
$purpleHighlight: #a65af6;
$orange: #fa937d;
$deepPurple: #36006e;
$spaceGrey: #102017;
$neonGreen: #59ffcf;
$acidGreen: #d2ff59;
$cream: #f8f4e4;

// gradients
$purpleToOrange: linear-gradient(180deg, #fa866e 0%, #8924f4 100%);
