.customSliderBg {
  height: 11px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 8px;
  z-index: -1;
  margin: 0;
}

.customInput {
  -webkit-appearance: none;
  width: 102%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #00000000;
  margin: 0 -1%;
}

.customInput:focus {
  outline: none;
}

/* Chrome & Safari  */
.customInput::-webkit-slider-runnable-track {
  background: #00000000;
  border: 1px solid #00000000;
  border-radius: 8px;
  width: 100%;
  height: 11px;
  cursor: pointer;
}

.customInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 41px;
  width: 41px;
  cursor: pointer;
  background: #9bffe2;
  margin-top: -17px;
  border-radius: 100px;
}

/* Firefox */
.customInput::-moz-range-track {
  background: #0e0d48;
  border: 1px solid #23116b;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.customInput::-moz-range-thumb {
  -webkit-appearance: none;
  height: 36px;
  width: 10px;
  background: #fff;
  box-radius: 20;
  border-radius: 8px;
  margin-top: -17px;
  cursor: pointer;
  background: #06f0f9;
  box-shadow: 0px 0px 14px #06f0f9;
}

/* IE */
.customInput::-ms-track {
  background: #0e0d48;
  border: 1px solid #23116b;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.customInput::-ms-thumb {
  height: 36px;
  width: 10px;
  background: #fff;
  border-radius: 8px;
  margin-top: -17px;
  cursor: pointer;
  background: #06f0f9;
  box-shadow: 0px 0px 14px #06f0f9;
}
