.rotate {
  /* transform: rotate(-90deg); */
}
.parenthover:hover .hover {
  box-shadow: 0px 0px 20px 0px rgba(6, 240, 249, 1);
}

.sortingBox > div {
  opacity: 0;
}
.sortingBox:hover > div {
  opacity: 1;
}

video {
  mix-blend-mode: exclusion;
  filter: brightness(1);
}

#WEB3_CONNECT_MODAL_ID > .web3modal-modal-lightbox {
  z-index: 100 !important;
}
