.burgerMenuComponent {
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  background-color: #8924f4;
  transition: all 0.3s ease;
  color: #ffffff;
  padding: 16px;
  padding-right: 32px;
}

.burgerMenuComponent > a > h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
}

.burgerMenuComponent > a {
  margin-bottom: 16px;
}

.burgerMenuComponent__icon {
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.burgerMenuComponent__svg {
  width: 18px;
  height: 15px;
  fill: #ffffff;
}

.burgerMenuComponent__socials {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.burgerMenuComponent__logo {
  width: 90px;
  height: 43px;
  margin-right: 2em;
}

.burgerMenuComponent__cancel-btn {
  cursor: pointer;
}
