.videoPlayer__video {
  width: 100%;
  transition: all 0.3s ease;
}

.videoPlayer__video--paused {
  /* filter: blur(10px); */
}

.videoPlayer__video-wrap {
  /* border-radius: 72px; */
  overflow: hidden;
}

.videoPlayer__controls {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: transparent;
}

.videoPlayer__button {
  display: flex;
  justify-content: end;
  width: 100px;
  height: 100px;
  transition: all 0.3s ease;
}

.videoPlayer__button:hover {
  transform: scale(1.1);
}
