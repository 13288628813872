.textEllipsed {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
.textEllipsed__text {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}
.textEllipsed__text-main {
  text-overflow: ellipsis;
}
.textEllipsed__text-overlay {
  direction: rtl;
}
