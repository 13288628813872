@import './variables.scss';
@import '../assets/fonts/index.css';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body,
html {
  overflow-x: hidden !important;
}

.ball {
  width: 600px;
  height: 600px;
  border-radius: 100%;
  background: $neonGreen;
  position: absolute;
  top: -100px;
  left: -100px;
  z-index: 1;
  @media (max-width: 736px) {
    width: 400px;
    height: 400px;
    top: -230px;
    left: -200px;
  }
  @media (max-width: 400px) {
    width: 118px;
    height: 118px;
    top: -40px;
    left: -2px;
  }

  div {
    background: $acidGreen;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  div:first-child {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background: transparent;
    border: 2px solid $neonGreen;
  }

  div:nth-child(2) {
    width: calc(160% + 60%);
    height: calc(160% + 60%);
    background: transparent;
    border: 2px solid $neonGreen;
  }

  div:nth-child(3) {
    width: calc(100% + 230%);
    height: calc(100% + 230%);
    background: transparent;
    border: 2px solid $neonGreen;
  }

  div:last-child {
    width: calc(100% + 250%);
    height: calc(100% + 250%);
    background: transparent;
    border: 4px solid $neonGreen;
  }
}

.link_without_borders {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

.small-ball {
  width: 134px;
  height: 134px;
  border-radius: 100%;
  background: $neonGreen;
  position: absolute;
  top: -85px;
  left: -65px;
  z-index: 1;

  div {
    background: $acidGreen;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  div:first-child {
    width: calc(140% + 10px);
    height: calc(140% + 10px);
    background: transparent;
    border: 2px solid $neonGreen;
  }
  div:nth-child(2) {
    width: calc(160% + 60%);
    height: calc(160% + 60%);
    background: transparent;
    border: 2px solid $neonGreen;
  }
}
.coming-soon-ball {
  width: 236px;
  height: 236px;
  border-radius: 100%;
  background: $neonGreen;

  div {
    background: $acidGreen;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  div:first-child {
    width: calc(140% + 10px);
    height: calc(140% + 10px);
    background: transparent;
    border: 2px solid $neonGreen;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'extended-ultrabold' !important;
}

span,
a,
p {
  font-family: 'sans-regular' !important;
}
.need-some-ufo {
  background: url(./../assets/NeedSomeNfo.png);
  mix-blend-mode: normal;
}

.unclaimed-rewards {
  background: url(./../assets/UnclaimedRewards.png);
  mix-blend-mode: normal;
}

.footer-title {
  font-family: 'extended-ultrabold' !important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  letter-spacing: 0.27px;

  @media (min-width: 736px) {
    margin-bottom: 15px;
    font-size: 18px;
  }
  @media (max-width: 736px) {
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.loader-item {
  height: 5px !important;
  background: #d2ff59 !important;
  border-radius: 5px !important;
}

.loader-container {
  background: #102017 !important;
  border-radius: 27px !important;
  position: static !important;
  padding: 25px !important;
  height: auto !important;
}

iframe {
  visibility: hidden !important;
  pointer-events: none !important;
}

#btnShowBridge {
  outline: none !important;
}
