@font-face {
  font-family: 'extended-regular';
  src: local('extended'), url(./MonumentExtended-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'extended-ultrabold';
  src: local('extended'),
    url(./MonumentExtended-Ultrabold.otf) format('opentype');
}

@font-face {
  font-family: 'sans-regular';
  src: local('objectSans'), url(./ObjectSans-Regular.woff) format('woff');
}

/* @font-face {
  font-family: 'Object Sans';
  src: url('ObjectSans/ObjectSans-Heavy.otf') format('opentype'),
    url('ObjectSans/ObjectSans-HeavySlanted.otf') format('opentype')
      url('ObjectSans/ObjectSans-Regular.otf') format('opentype')
      url('ObjectSans/ObjectSans-Slanted.otf') format('opentype');
} */
