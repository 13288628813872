.customRangeBg {
  /* background-color: #102017; */
  height: 10px;
  position: relative;
  border-radius: 8px;
  top: 85px;
  z-index: -1;
}

.rangeSlider {
  width: 100%;
  margin: 18px 0;
  -webkit-appearance: none;
  position: absolute;
  top: 10px;
  z-index: 10;
  height: 120px;
  background-color: #00000000;
  z-index: 10;
}

.rangeSlider:focus {
  outline: none;
}

/* Chrome & Safari  */
.rangeSlider::-webkit-slider-runnable-track {
  background: #00000000;
  border: 1px solid #00000000;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 41px;
  width: 41px;
  cursor: pointer;
  background: #9bffe2;
  margin-top: -15px;
  margin-left: -10px;
  border-radius: 100px;
}

/* Firefox */
.rangeSlider::-moz-range-track {
  background: #0e0d48;
  border: 1px solid #23116b;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  -webkit-appearance: none;
  height: 36px;
  width: 10px;
  background: #fff;
  box-radius: 20;
  border-radius: 8px;
  margin-top: -13px;
  cursor: pointer;
  background: #06f0f9;
  box-shadow: 0px 0px 14px #06f0f9;
}

/* IE */
.rangeSlider::-ms-track {
  background: #0e0d48;
  border: 1px solid #23116b;
  border-radius: 8px;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.rangeSlider::-ms-thumb {
  height: 36px;
  width: 10px;
  background: #fff;
  border-radius: 8px;
  margin-top: -13px;
  cursor: pointer;
  background: #06f0f9;
  box-shadow: 0px 0px 14px #06f0f9;
}
